import { Link } from "gatsby"
import React from "react"

import styles from "./blog-preview.module.css"

function BlogPreview(props) {
  return (
    <Link className={styles.root} to={`/blog/${props.slug.current}`}>
      <h2 className={styles.title}>{props.title}</h2>
      <p>{props.excerpt}</p>
      <small>{props.publishedAt}</small>
    </Link>
  )
}

export default BlogPreview
