import React from "react"
import { graphql } from "gatsby"
import Container from "../components/container"
import GraphQLErrorList from "../components/graphql-error-list"
import BlogPreviewGrid from "../components/blog-preview-grid"
import SEO from "../components/seo"
import Layout from "../containers/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers"

export const query = graphql`
  query BlogPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)blog/" }) {
      id
      _id
      title
      introduction
      _rawBody
    }
    blog: allSanityBlog(
      limit: 12
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          publishedAt
          title
          excerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const BlogPage = props => {
  const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data && data.page
  const blogNodes =
    data &&
    data.blog &&
    mapEdgesToNodes(data.blog).filter(filterOutDocsWithoutSlugs)

  return (
    <Layout>
      <SEO hidden title="Blog" />
      <Header header={page.title} subheader={page.introduction} />
      <Container>
        {blogNodes && blogNodes.length > 0 && (
          <BlogPreviewGrid nodes={blogNodes} />
        )}
      </Container>
      <Footer />
    </Layout>
  )
}

export default BlogPage
